import React, { useState } from 'react'

import { Link } from 'gatsby'

import styled from 'styled-components'

export default () => {
	const [openLinkMenu, setOpenLinkMenu] = useState(false)

	return (
		<Nav>
			{ pages.map(page => (
				<ImportantLink
					key={ page.value }
					to={ `${ page.value }` }
					alwaysVisible={ page.important }
					isPromoPage={ page.promo }
					testimonial={ page.value === '/testimonials' }>
					{ page.title }
				</ImportantLink>
			)) }
			<NonImportantLinkMenuContainer onClick={ () => setOpenLinkMenu(!openLinkMenu) }>
				More<Red>..</Red>
				{ openLinkMenu && (
					<NonImportantMenu>
						{ pages.filter(page => !page.important).map(page => (
							<NonImportantNavLink
								key={ page.value }
								to={ `${ page.value }` }
								isPromoPage={ page.promo }>
								{ page.title }
							</NonImportantNavLink>
						)) }
					</NonImportantMenu>
				) }
			</NonImportantLinkMenuContainer>
		</Nav>
	)
}

const Nav = styled.nav`
	text-align: center;
	display: block;
`
const sharedNavItemStyle = `
	text-decoration: none;
	font-weight: 500;
	font-size: 1.4em;
	margin-left: 25px;
	@media (max-width: 774px) {
		margin-left: 20px
	}
	:first-child {
		margin-left: 0;
	}
`
const ImportantLink = styled(({ isPromoPage, alwaysVisible, testimonial, ...rest }) => <Link { ...rest } />).attrs({
	activeClassName: 'active-nav-link'
})`
	${ sharedNavItemStyle }
	color: ${ ({ testimonial }) => !testimonial ? '#444' : 'hsl(356,70%,53%)' };
	:hover {
		padding-bottom: 8px;
		border-bottom: 6px solid #ddd;
	}
	${ ({ alwaysVisible }) => !alwaysVisible ? `
		@media (max-width: 723px) {
			visibility: hidden;
			display: none;
		}
	` : `` }
	&.active-nav-link {
		padding-bottom: 8px;
		border-bottom: 6px solid #ddd;
	}
`
const NonImportantLinkMenuContainer = styled.div`
	visibility: hidden;
	display: none;
	@media (max-width: 723px) {
		visibility: visible;
		display: inline-block;
		position: relative;
		cursor: pointer;
		${ sharedNavItemStyle }
	}
`
const Red = styled.span`
	color: red;
`
const NonImportantMenu = styled.div`
	position: absolute;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	z-index: 1000;
	font-size: 1.0em;
	padding: 0;
	min-width: 150px;
	left: -80px;
	top: 30px;
	boxShadow: 4px 4px 15px rgba(100, 100, 100, 0.15);
`
const NonImportantNavLink = styled(({ isPromoPage, ...rest }) => <Link { ...rest }/>)`
	${ sharedNavItemStyle }
	display: block;
	font-size: 1em;
	margin: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	padding: 5px 10px;
	@media (max-width: 774px) {
		margin-left: 0;
	}
	:first-child {
		margin-left: 0;
	}
	:last-child {
		border-bottom: 0;
	}
	:hover {
		padding-bottom: 5px;
		border-bottom: inherited;
		color: #aaa;
	}
	color: #444;
`

const pages = [
	{
		title: 'Home',
		value: '/',
		important: true
	},
	{
		title: 'Instructors',
		value: '/instructors'
	},
	//{
	//	title: '4Kids',
	//	value: '/gallery',
	//	important: true
	//},
	{
		title: 'Contortion',
		value: '/contortion',
		important: true
	},
	{
		title: 'Wu Shu',
		value: '/about'
	},
	{
		title: 'Skills',
		value: '/skills'
	},
	{
		title: 'Testimonials',
		value: '/testimonials'
	},
	{
		title: 'Contact',
		value: '/contact',
		important: true,
	}
]
